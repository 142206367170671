import blue from "../../assets/images/bg-foot.png";
import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footercontainer">
      <p className="tagged">All Rights Reserved.</p>
      <img src={blue} className="bg-foot" alt="bg-foot" />
    </div>
  );
}

export default Footer;
