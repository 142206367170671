import bg from "./assets/images/bg.png";
import grx from "./assets/images/gloss-rx.png";
import grxcar from "./assets/images/grx-animation.png";
import jbluery from "./assets/images/jbluery.png";
import jbluerymural from "./assets/images/jbluery-ie.png";
import henosis from "./assets/images/henosis-hospitality.png";
import gopra from "./assets/images/gopraperformance.png";
import hickman from "./assets/images/hickmanevents.png";
import giorgio from "./assets/images/giorgio.png";
import "./App.scss";
import benji from "./assets/images/benji.png";
import dna from "./assets/images/dna.png";
import linkedin from "./assets/images/linkedin.png";
import enter from "./assets/images/enter.png";
import { BrowserRouter as Router } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Footer from "./components/Footer/Footer";
import { useHref } from "react-router";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="container">
          <div className="innercontainer">
            <div className="innercontainer__nav">
              <p className="innercontainer__nav--info">
                Jesse A. Sanchez
                <br />
                Portfolio
              </p>
              <p className="innercontainer__script">
                Welcome!
                <br />
                Thank you for your interest!
                <br />
                <br />
                <br />
                <br />
                <HashLink to="/#welcome">
                  <img
                    src={enter}
                    className="enter"
                    alt="click-here-to-enter-website"
                  />
                </HashLink>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <a href="https://www.glossrx.com/">
                  <img
                    src={grx}
                    className="grx"
                    alt="gloss-rx-logo-registered-trademark"
                    id="welcome"
                  />
                </a>
                <br />
                <br />
                This brand was curated to treat the exotic car community. I
                founded this company with my business partner Jorge A. Vergara a
                legend in "Paint and body work" with over 25 years of experience
                working with luxury vehicles.
                <br />
                <br />
                We were able to Franchise the brand and is now located in
                Deerfield Beach, Florida!
                <br />
                <br />
                I help create the brand image, shop design, and first marketing
                campaign. In addition to developing our website and establishing
                a strong online social media presence.
                <br />
                <img
                  src={grxcar}
                  className="grxcar"
                  alt="gloss-rx-porsche-stone-grey-widebodykit"
                />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <a href="https://www.pinksauce.io/">
                  <img
                    src={jbluery}
                    className="jbluery"
                    alt="jbluery-trademark-logo"
                  />
                </a>
                <br />
                <br />
                This brand belongs to a one of a kind artist and muralist
                Jessica A. Figueroa.
                <br />
                <br />
                Jessica asked me to join her brand as co-owner and it has got to
                be one of the most exciting experiences!
                <br />
                <br />
                I.E.
                <br />
                <br />
                <a href="https://www.twitter.com/jbluery/">
                  <img
                    src={jbluerymural}
                    className="jbluerymural"
                    alt="jbluery-mural-in-brickell-city-centre"
                  />
                </a>
                <br />
                <br />
                This is an awesome example of how much of a pleasure it is to be
                a part of the team. As vice president I assist in sales and
                marketing. This wall was done for Art Basel 2021 during art week
                for the infamous Building.co here in Brickell City Centre Miami,
                Florida.
                <br />
                <br />I also developed her NFT collection PinkSauce and manage
                her OpenSeas account, websites and social media handles. We are
                currently in development of her first App to launch on IOS app
                store and Google Playstore!
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <a href="https://www.instagram.com/henosishospitality/">
                  <img
                    src={henosis}
                    className="henosis"
                    alt="henosis-hospitality-marketing-and-holdings-company"
                  />
                </a>
                <br />
                <br />
                This brand is my freelance gateway to express my creativty and
                skills with helping other brands grow.
                <br />
                <br />
                Here is a list of current clients:
                <br />
                <br />
                <a href="https://www.youtube.com/watch?v=Yr92H3n3cFo">
                  <img
                    src={gopra}
                    className="gopra"
                    alt="go-pra-performance-art-and-dance-company-lazaro-godoy-carlota-pradera"
                  />
                </a>
                <br />
                <br />
                <a href="https://www.hickmanevents.com/">
                  <img
                    src={hickman}
                    className="hickman"
                    alt="hickman-events-party-weddings-ceramonys-and-more"
                  />
                </a>
                <br />
                <br />
                <a href="https://www.instagram.com/giorgiomiami/">
                  <img
                    src={giorgio}
                    className="giorgio"
                    alt="giorgio-miami-fashion-vlog"
                  />
                </a>
                <br />
                <br />
                <a href="https://www.benjigear.com/">
                  <img
                    src={benji}
                    className="benji"
                    alt="benji-gear-sportswear"
                  />
                </a>
                <br />
                <br />
                <a href="https://www.dnacleanserv.com/">
                  <img src={dna} className="dna" alt="dna-cleaning-services" />
                </a>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Thanks for viewing my page!
                <br />
                connect with me below
                <br />
                <br />
                <a href="https://www.linkedin.com/in/jesse-a-sanchez">
                  <img
                    src={linkedin}
                    className="linkedin"
                    alt="jesse-a-sanchez"
                  />
                </a>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Last updated: Thursday December 23, 2021
                <br />
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
          <img src={bg} className="bg" alt="bg" />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
